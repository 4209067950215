/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-excel-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12 0H4a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V2a2 2 0 00-2-2M5.884 4.68L8 7.219l2.116-2.54a.5.5 0 11.768.641L8.651 8l2.233 2.68a.5.5 0 01-.768.64L8 8.781l-2.116 2.54a.5.5 0 01-.768-.641L7.349 8 5.116 5.32a.5.5 0 11.768-.64"/>',
    },
});
